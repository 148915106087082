<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { useForm } from 'vee-validate';
import { object, string, number, boolean } from 'yup';
import { baseServicePricesApi, type BaseServicePriceForm } from '@/api/base-service-prices';
import PriceInput from '@/components/price-input.vue';

const { t } = useI18n();

const props = defineProps<{
  baseServicePrice?: BaseServicePriceForm | null,
}>();

const emit = defineEmits(['close']);

const formTitle = computed(() => (
  props.baseServicePrice ? 'Editar Precio Base' : 'Nuevo Precio Base'
));

const validationSchema = object({
  serviceType: string().required(t('baseServicePriceForm.fieldRequired')),
  weekPrice: number()
    .required(t('baseServicePriceForm.fieldRequired'))
    .typeError(t('baseServicePriceForm.fieldPositive'))
    .min(0, t('baseServicePriceForm.fieldPositive')),
  weekCost: number()
    .required(t('baseServicePriceForm.fieldRequired'))
    .typeError(t('baseServicePriceForm.fieldPositive'))
    .min(0, t('baseServicePriceForm.fieldPositive')),
  weekendPrice: number()
    .required(t('baseServicePriceForm.fieldRequired'))
    .typeError(t('baseServicePriceForm.fieldPositive'))
    .min(0, t('baseServicePriceForm.fieldPositive')),
  weekendCost: number()
    .required(t('baseServicePriceForm.fieldRequired'))
    .typeError(t('baseServicePriceForm.fieldPositive'))
    .min(0, t('baseServicePriceForm.fieldPositive')),
  withTaxBill: boolean()
    .required(t('baseServicePriceForm.fieldRequired')),
});

const { handleSubmit, values, errors, defineField } = useForm({
  validationSchema,
  initialValues: {
    serviceType: props.baseServicePrice?.serviceType ?? '',
    weekPrice: props.baseServicePrice?.weekPrice ?? 0,
    weekCost: props.baseServicePrice?.weekCost ?? 0,
    weekendPrice: props.baseServicePrice?.weekendPrice ?? 0,
    weekendCost: props.baseServicePrice?.weekendCost ?? 0,
    withTaxBill: props.baseServicePrice?.withTaxBill ?? false,
  },
});

const [serviceType, serviceTypeAttrs] = defineField('serviceType');
const [weekPrice, weekPriceAttrs] = defineField('weekPrice');
const [weekCost, weekCostAttrs] = defineField('weekCost');
const [weekendPrice, weekendPriceAttrs] = defineField('weekendPrice');
const [weekendCost, weekendCostAttrs] = defineField('weekendCost');
const [withTaxBill, withTaxBillAttrs] = defineField('withTaxBill');

const queryClient = useQueryClient();

const {
  mutate: createBaseServicePrice,
  isPending: isCreatePending,
  isError: isCreateError,
  error: createError,
} = useMutation<unknown, AxiosError>({
  mutationFn: () => baseServicePricesApi.create({ ...values }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['baseServicePrices'] });
    emit('close');
  },
});

const {
  mutate: updateBaseServicePrice,
  isPending: isUpdatePending,
  isError: isUpdateError,
  error: updateError,
} = useMutation<unknown, AxiosError>({
  mutationFn: () => baseServicePricesApi.update({ id: props.baseServicePrice?.id, ...values }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['baseServicePrices'] });
    emit('close');
  },
});

const onSubmit = handleSubmit(() => {
  if (props.baseServicePrice) {
    updateBaseServicePrice();
  } else {
    createBaseServicePrice();
  }
});

const serviceTypes = [
  { title: 'Terapeuta ocupacional', value: 'occupational_therapist' },
  { title: 'Kinesiólogo/a', value: 'kinesiologist' },
  { title: 'Fonoaudiólogo/a', value: 'phonoaudiologist' },
];

function errorMessage(error: AxiosError) {
  const priceAlreadyExistsCode = 422;
  if (error.response?.status === priceAlreadyExistsCode) {
    return t('baseServicePriceForm.priceAlreadyExists');
  }

  return t('userSession.defaultError');
}
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="serviceType"
              v-bind="serviceTypeAttrs"
              :items="serviceTypes"
              item-title="title"
              item-value="value"
              label="Tipo de Servicio"
              variant="outlined"
              :error-messages="errors.serviceType"
              :disabled="!!baseServicePrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekPrice"
              v-bind="weekPriceAttrs"
              label="Precio Semana"
              :error-messages="errors.weekPrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekendPrice"
              v-bind="weekendPriceAttrs"
              label="Precio Fin de Semana"
              :error-messages="errors.weekendPrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekCost"
              v-bind="weekCostAttrs"
              label="Pago Semana (líquido)"
              :error-messages="errors.weekCost"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekendCost"
              v-bind="weekendCostAttrs"
              label="Pago Fin de Semana (líquido)"
              :error-messages="errors.weekendCost"
            />
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="withTaxBill"
              v-bind="withTaxBillAttrs"
              :label="t('baseServicePriceForm.withTaxBillTitle')"
              inline
            >
              <v-radio
                :label="t('baseServicePriceForm.yes')"
                :value="true"
              />
              <v-radio
                :label="t('baseServicePriceForm.no')"
                :value="false"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        variant="text"
        @click="emit('close')"
      >
        Cancelar
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        variant="text"
        :loading="isCreatePending || isUpdatePending"
        @click="onSubmit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
    <v-alert
      v-if="isCreateError && createError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorMessage(createError) }}
    </v-alert>
    <v-alert
      v-if="isUpdateError && updateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorMessage(updateError) }}
    </v-alert>
  </v-card>
</template>
