<script setup lang="ts">
import { ref, watch } from 'vue';
import { formatNumber, isNumber, unformatNumber } from '@/utils/number-functions';

const props = defineProps<{
  modelValue: number;
  label: string;
  errorMessages?: string;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const displayValue = ref(formatNumber(Math.max(0, props.modelValue)));

watch(() => props.modelValue, (newValue) => {
  displayValue.value = formatNumber(Math.max(0, newValue));
});

function updateValue(event: Event) {
  const input = event.target as HTMLInputElement;
  const unformattedValue = unformatNumber(input.value);
  const numericValue = Number.isNaN(unformattedValue) ? 0 : Math.max(0, unformattedValue);
  emit('update:modelValue', numericValue);
  displayValue.value = formatNumber(numericValue);
}
</script>

<template>
  <v-text-field
    :model-value="displayValue"
    :label="label"
    :error-messages="errorMessages"
    :disabled="disabled"
    prefix="$"
    variant="outlined"
    @input="updateValue"
    @wheel="$event.target.blur()"
    @keypress="isNumber($event)"
  />
</template>
