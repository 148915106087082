function isNumber(event: KeyboardEvent): void {
  if (!/\d/.test(event.key)) event.preventDefault();

  return;
}

function formatNumber(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function unformatNumber(value: string): number {
  return parseInt(value.replace(/\./g, ''), 10);
}

export { isNumber, formatNumber, unformatNumber };
