import { api } from './index';

const BASE_PATH = '/api/internal/service_prices';

export type ServicePrice = {
  id: number;
  patientId: number;
  serviceType: string;
  weekPrice: number;
  weekendPrice: number;
  weekCost: number;
  weekendCost: number;
  withTaxBill: boolean;
}

export interface ServicePriceForm {
  id?: number;
  patientId: number;
  serviceType: string;
  weekPrice: number;
  weekendPrice: number;
  weekCost: number;
  weekendCost: number;
  withTaxBill: boolean;
}

export const servicePricesApi = {
  index(patientId: number): Promise<Array<ServicePrice>> {
    return api({
      method: 'get',
      url: `${BASE_PATH}?patient_id=${patientId}`,
    }).then(response => response.data.servicePrices);
  },
  create(servicePriceForm: ServicePriceForm): Promise<ServicePrice> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { servicePrice: servicePriceForm },
    }).then(response => response.data.servicePrice);
  },
  update(servicePriceForm: Partial<ServicePriceForm>): Promise<ServicePrice> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${servicePriceForm.id}`,
      data: { servicePrice: servicePriceForm },
    }).then(response => response.data.servicePrice);
  },
};
