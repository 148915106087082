<script setup lang="ts">
import { type Shift } from '@/api/shifts';

const props = defineProps<{ shift: Shift }>();

const headers = [
  { title: 'Fecha', value: 'humanCreatedAt' },
  { title: 'Texto', value: 'text' },
  { title: 'Ver Completo', value: 'formattedText' },
];
</script>
<template>
  <v-data-table
    :headers="headers"
    :items="props.shift.reportTexts"
    :items-per-page="-1"
    :height="props.shift.reportTexts.length > 0 ? 400 : 200"
    item-key="id"
    class="pa-2"
    no-data-text="No hay registros escritos"
    hide-default-footer
  >
    <template #item.text="{ item }">
      <span
        class="text-truncate d-inline-block"
        style="max-width: 200px"
      >
        {{ item.text }}
      </span>
    </template>
    <template #item.formattedText="{ item }">
      <v-dialog max-width="600">
        <template #activator="{ props: activatorProps }">
          <v-btn
            v-if="item.formattedText?.length > 0"
            v-bind="activatorProps"
            icon="mdi-eye"
            size="small"
            class="me-2"
          />
        </template>
        <template #default>
          <v-card>
            <!-- eslint-disable-next-line vue/no-v-html vue/no-v-text-v-html-on-component -->
            <v-card-text v-html="item.formattedText" />
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template #bottom />
  </v-data-table>
</template>
