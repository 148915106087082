import { api } from './index';

const BASE_PATH = '/api/internal/base_service_prices';

export type BaseServicePrice = {
  id: number;
  serviceType: string;
  weekPrice: number;
  weekendPrice: number;
  weekCost: number;
  weekendCost: number;
  withTaxBill: boolean;
}

export interface BaseServicePriceForm {
  id?: number;
  serviceType: string;
  weekPrice: number;
  weekendPrice: number;
  weekCost: number;
  weekendCost: number;
  withTaxBill: boolean;
}

export const baseServicePricesApi = {
  index(): Promise<Array<BaseServicePrice>> {
    return api({
      method: 'get',
      url: BASE_PATH,
    }).then(response => response.data.baseServicePrices);
  },
  create(baseServicePriceForm: BaseServicePriceForm): Promise<BaseServicePrice> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { baseServicePrice: baseServicePriceForm },
    }).then(response => response.data.baseServicePrice);
  },
  update(baseServicePriceForm: Partial<BaseServicePriceForm>): Promise<BaseServicePrice> {
    return api({
      method: 'put',
      url: `${BASE_PATH}/${baseServicePriceForm.id}`,
      data: { baseServicePrice: baseServicePriceForm },
    }).then(response => response.data.baseServicePrice);
  },
};
