<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { AxiosError } from 'axios';
import { useMutation, useQueryClient, useQuery } from '@tanstack/vue-query';
import { useForm } from 'vee-validate';
import { object, string, number, boolean } from 'yup';
import { servicePricesApi, type ServicePriceForm } from '@/api/service-prices';
import { baseServicePricesApi, type BaseServicePrice } from '@/api/base-service-prices';
import PriceInput from '@/components/price-input.vue';

const { t } = useI18n();

const props = defineProps<{
  patientId: number,
  servicePrice?: ServicePriceForm | null,
}>();

const emit = defineEmits(['close']);

const formTitle = computed(() => (
  props.servicePrice ? 'Editar Precio de Servicio' : 'Nuevo Precio de Servicio'
));

const validationSchema = object({
  serviceType: string().required(t('servicePriceForm.fieldRequired')),
  weekPrice: number()
    .required(t('servicePriceForm.fieldRequired'))
    .typeError(t('servicePriceForm.fieldPositive'))
    .min(0, t('servicePriceForm.fieldPositive')),
  weekCost: number()
    .required(t('servicePriceForm.fieldRequired'))
    .typeError(t('servicePriceForm.fieldPositive'))
    .min(0, t('servicePriceForm.fieldPositive')),
  weekendPrice: number()
    .required(t('servicePriceForm.fieldRequired'))
    .typeError(t('servicePriceForm.fieldPositive'))
    .min(0, t('servicePriceForm.fieldPositive')),
  weekendCost: number()
    .required(t('servicePriceForm.fieldRequired'))
    .typeError(t('servicePriceForm.fieldPositive'))
    .min(0, t('servicePriceForm.fieldPositive')),
  withTaxBill: boolean().required(t('servicePriceForm.fieldRequired')),
});

const { handleSubmit, values, errors, defineField } = useForm({
  validationSchema,
  initialValues: {
    serviceType: props.servicePrice?.serviceType ?? '',
    weekPrice: props.servicePrice?.weekPrice ?? 0,
    weekCost: props.servicePrice?.weekCost ?? 0,
    weekendPrice: props.servicePrice?.weekendPrice ?? 0,
    weekendCost: props.servicePrice?.weekendCost ?? 0,
    withTaxBill: props.servicePrice?.withTaxBill ?? false,
  },
});

const [serviceType, serviceTypeAttrs] = defineField('serviceType');
const [weekPrice, weekPriceAttrs] = defineField('weekPrice');
const [weekCost, weekCostAttrs] = defineField('weekCost');
const [weekendPrice, weekendPriceAttrs] = defineField('weekendPrice');
const [weekendCost, weekendCostAttrs] = defineField('weekendCost');
const [withTaxBill, withTaxBillAttrs] = defineField('withTaxBill');

const queryClient = useQueryClient();

const { data: baseServicePrices } = useQuery({
  queryKey: ['baseServicePrices'],
  queryFn: () => baseServicePricesApi.index(),
  initialData: [],
});

const {
  mutate: createServicePrice,
  isPending: isCreatePending,
  isError: isCreateError,
  error: createError,
} = useMutation<unknown, AxiosError>({
  mutationFn: () => servicePricesApi.create({ ...values, patientId: props.patientId }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['servicePrices'] });
    emit('close');
  },
});

const {
  mutate: updateServicePrice,
  isPending: isUpdatePending,
  isError: isUpdateError,
  error: updateError,
} = useMutation<unknown, AxiosError>({
  mutationFn: () => servicePricesApi.update({ id: props.servicePrice?.id, ...values }),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['servicePrices'] });
    emit('close');
  },
});

const onSubmit = handleSubmit(() => {
  if (props.servicePrice) {
    updateServicePrice();
  } else {
    createServicePrice();
  }
});

const serviceTypes = [
  { title: 'Terapeuta ocupacional', value: 'occupational_therapist' },
  { title: 'Kinesiólogo/a', value: 'kinesiologist' },
  { title: 'Fonoaudiólogo/a', value: 'phonoaudiologist' },
];

function errorMessage(error: AxiosError) {
  const priceAlreadyExistsCode = 422;
  if (error.response?.status === priceAlreadyExistsCode) {
    return t('servicePriceForm.priceAlreadyExists');
  }

  return t('userSession.defaultError');
}

watch(serviceType, () => {
  const baseServicePriceForServiceType = baseServicePrices?.value?.find(
    (baseServicePrice: BaseServicePrice) => baseServicePrice.serviceType === values.serviceType,
  );
  if (!baseServicePriceForServiceType) return;

  weekPrice.value = baseServicePriceForServiceType.weekPrice;
  weekendPrice.value = baseServicePriceForServiceType.weekendPrice;
  weekCost.value = baseServicePriceForServiceType.weekCost;
  weekendCost.value = baseServicePriceForServiceType.weekendCost;
  withTaxBill.value = baseServicePriceForServiceType.withTaxBill;
});
</script>

<template>
  <v-card :loading="!baseServicePrices">
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="serviceType"
              v-bind="serviceTypeAttrs"
              :items="serviceTypes"
              item-title="title"
              item-value="value"
              label="Tipo de Servicio"
              variant="outlined"
              :error-messages="errors.serviceType"
              :disabled="!!servicePrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekPrice"
              v-bind="weekPriceAttrs"
              label="Precio Semana"
              :error-messages="errors.weekPrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekendPrice"
              v-bind="weekendPriceAttrs"
              label="Precio Fin de Semana"
              :error-messages="errors.weekendPrice"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekCost"
              v-bind="weekCostAttrs"
              label="Pago Semana (líquido)"
              :error-messages="errors.weekCost"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <price-input
              v-model="weekendCost"
              v-bind="weekendCostAttrs"
              label="Pago Fin de Semana (líquido)"
              :error-messages="errors.weekendCost"
            />
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="withTaxBill"
              v-bind="withTaxBillAttrs"
              :label="t('servicePriceForm.withTaxBillTitle')"
              inline
            >
              <v-radio
                :label="t('servicePriceForm.yes')"
                :value="true"
              />
              <v-radio
                :label="t('servicePriceForm.no')"
                :value="false"
              />
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-btn
        variant="text"
        @click="emit('close')"
      >
        Cancelar
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        variant="text"
        :loading="isCreatePending || isUpdatePending"
        @click="onSubmit"
      >
        Guardar
      </v-btn>
    </v-card-actions>
    <v-alert
      v-if="isCreateError && createError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorMessage(createError) }}
    </v-alert>
    <v-alert
      v-if="isUpdateError && updateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorMessage(updateError) }}
    </v-alert>
  </v-card>
</template>
