import { api } from './index';

const BASE_PATH = '/api/internal/charge_pdfs';

export type ChargePdf = {
  publicId: string;
}

export interface ChargePdfForm {
  patientId: number;
  fromDate: string;
  toDate: string;
}

export const chargePdfsApi = {
  create(chargePdfForm: ChargePdfForm): Promise<ChargePdf> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { chargePdf: chargePdfForm },
    }).then(response => response.data.chargePdf);
  },
};
