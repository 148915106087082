<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { chargesApi, type Charge, type PDFCharge, type XlsxCharges } from '@/api/charges';
import { useQuery, useMutation } from '@tanstack/vue-query';
import { useFilters } from '@/composables/useFilters';
import { formatCurrency } from '@/utils/currency';
import { useChargesStore } from '@/stores/charges';
import ChargesListDetail from '@/components/charges/charges-list-detail.vue';
import { chargePdfsApi } from '@/api/charge-pdfs';
import { useDateRangeValidation } from '@/composables/useDateRangeValidation';

const props = defineProps<{
  formattedDates: {
    fromDate: string;
    toDate: string;
  };
}>();

const store = useChargesStore();
if (!store.fromDate) store.fromDate = props.formattedDates.fromDate;
if (!store.toDate) store.toDate = props.formattedDates.toDate;

const filters = ref<{
  fromDate: string;
  toDate: string;
}>({
  fromDate: store.fromDate,
  toDate: store.toDate,
});

const queryParams = useFilters(filters.value);
const { isValidDateRange, dateRangeError } = useDateRangeValidation(filters);

const { data: charges, refetch, isFetching } = useQuery({
  queryKey: ['charges'],
  queryFn: () => chargesApi.index(queryParams.value),
  initialData: [],
});

watch(filters, () => {
  store.fromDate = filters.value.fromDate;
  store.toDate = filters.value.toDate;
}, { deep: true });

const headers = [
  { title: 'Paciente', value: 'patient.fullName' },
  { title: 'Apoderado', value: 'patient.representative.fullName' },
  { title: 'Cantidad de Turnos', value: 'shiftsCount' },
  { title: 'Monto Turnos', value: 'price' },
  { title: 'Ajustes', value: 'totalChargeMovements' },
  { title: 'Monto a Cobrar', value: 'amountPendingToCharge' },
  { title: 'Ver Detalle', value: 'actions' },
];

const totalShiftsCount = computed(
  () => charges.value.reduce((acc: number, charge: Charge) => acc + charge.shiftsCount, 0),
);
const totalPrice = computed(
  () => charges.value.reduce((acc: number, charge: Charge) => acc + charge.price, 0),
);
const totalChargeMovements = computed(
  () => charges.value.reduce((acc: number, charge: Charge) => acc + charge.totalChargeMovements, 0),
);
const totalAmountPendingToCharge = computed(
  () => charges.value.reduce((acc: number, charge: Charge) => acc + charge.amountPendingToCharge, 0),
);

const pdfDownloadSnackbarAlert = ref(false);
function downloadPatientPDF(patientCharge: Charge) {
  pdfDownloadSnackbarAlert.value = true;
  chargesApi.show(patientCharge.patient.id, queryParams.value, 'pdf').then((response: PDFCharge) => {
    const { headers: responseHeaders, data, data: { type } } = response;
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.download = responseHeaders['content-disposition'].split('"')[1];
    link.click();
  });
}

const emailSnackbarAlert = ref(false);
function sendPatientChargesViaEmail(patientCharge: Charge) {
  emailSnackbarAlert.value = true;
  chargesApi.show(patientCharge.patient.id, queryParams.value, 'json');
}

function localizedDate(date: string) {
  return date.split('-').reverse().join('/');
}

const search = ref('');

const xlsxDownloadSnackbarAlert = ref(false);
function downloadChargesXlsx() {
  xlsxDownloadSnackbarAlert.value = true;
  chargesApi.indexAsXlsx(queryParams.value).then((response: XlsxCharges) => {
    const { headers: responseHeaders, data, data: { type } } = response;
    const url = window.URL.createObjectURL(new Blob([data], { type }));
    const link = document.createElement('a');
    link.href = url;
    link.download = responseHeaders['content-disposition'].split('"')[1];
    link.click();
  });
}

const whatsappSnackbarAlert = ref(false);
const errorSnackbarAlert = ref(false);
const errorMessage = ref('');

function generateWhatsappMessage(charge: Charge, pdfUrl: string) {
  const message = 'Hola, envío el detalle de los servicios prestados:\n' +
    '------------------\n' +
    `*Paciente*: ${charge.patient.fullName}\n` +
    `*Desde*: ${localizedDate(filters.value.fromDate)}\n` +
    `*Hasta*: ${localizedDate(filters.value.toDate)}\n` +
    '------------------\n' +
    'Puedes ver el detalle en PDF en el siguiente link:\n' +
    `${pdfUrl}`;

  return encodeURIComponent(message);
}

const { mutateAsync: createChargePdf, isPending: isCreatingChargePdf } = useMutation({
  mutationFn: (charge: Charge) => chargePdfsApi.create({
    patientId: charge.patient.id,
    fromDate: filters.value.fromDate,
    toDate: filters.value.toDate,
  }),
});

function clickWhatsappLink(phoneNumber: string, message: string) {
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
  const link = document.createElement('a');
  link.href = whatsappUrl;
  link.setAttribute('target', '_blank');
  link.setAttribute('rel', 'noopener noreferrer');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

async function sendWhatsappMessage(charge: Charge) {
  whatsappSnackbarAlert.value = true;
  try {
    const chargePdf = await createChargePdf(charge);
    const pdfUrl = `${window.location.origin}/cp/${chargePdf.publicId}`;
    const message = generateWhatsappMessage(charge, pdfUrl);
    clickWhatsappLink(charge.patient.representative.phoneNumber, message);
  } catch (error) {
    errorMessage.value = 'Hubo un error generando el mensaje de WhatsApp. Por favor, inténtalo nuevamente.';
    errorSnackbarAlert.value = true;
  } finally {
    whatsappSnackbarAlert.value = false;
  }
}
</script>
<template>
  <v-card class="w-100">
    <v-data-table
      :headers="headers"
      :header-props="{ class: 'font-weight-bold' }"
      :items="charges"
      :items-per-page="-1"
      :search="search"
      :loading="isFetching"
      loading-text="Cargando los cobros..."
      no-data-text="No hay cobros en este rango de fechas"
    >
      <template #top>
        <v-toolbar
          color="white"
          flat
        >
          <v-toolbar-title class="font-weight-bold">
            Cobros a Clientes
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="justify-start d-flex align-center">
            <v-text-field
              v-model="filters.fromDate"
              class="px-2 v-col-3"
              type="date"
              label="Fecha desde"
              variant="outlined"
              density="compact"
              :error-messages="dateRangeError"
            />
            <v-text-field
              v-model="filters.toDate"
              class="px-2 v-col-3"
              type="date"
              label="Fecha hasta"
              variant="outlined"
              density="compact"
              :error-messages="dateRangeError"
            />
            <v-btn
              class="mb-6 mr-1 bg-primary"
              prepend-icon="mdi-magnify"
              :disabled="isFetching || !isValidDateRange"
              @click="refetch"
            >
              Filtrar
            </v-btn>
            <v-spacer />
            <v-btn
              class="mb-6 mr-4"
              color="primary"
              prepend-icon="mdi-microsoft-excel"
              variant="outlined"
              :disabled="isFetching || xlsxDownloadSnackbarAlert"
              @click="downloadChargesXlsx"
            >
              Descargar
            </v-btn>
            <v-snackbar
              v-model="xlsxDownloadSnackbarAlert"
              :timeout="2000"
              color="primary"
              rounded="pill"
            >
              Se está generando el archivo Excel, puede tomar unos segundos...
            </v-snackbar>
          </v-row>
          <v-row class="justify-end d-flexn">
            <v-text-field
              v-model="search"
              label="Buscar"
              class="mx-4 v-col-3"
              prepend-inner-icon="mdi-magnify"
              single-line
              variant="underlined"
              hide-details
            />
          </v-row>
        </v-card-text>
      </template>
      <template #item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>
      <template #item.totalChargeMovements="{ item }">
        {{ formatCurrency(item.totalChargeMovements) }}
      </template>
      <template #item.amountPendingToCharge="{ item }">
        <v-chip color="primary">
          {{ formatCurrency(item.amountPendingToCharge) }}
        </v-chip>
      </template>
      <template #item.actions="{ item }">
        <v-dialog
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-eye"
              size="small"
              class="me-2"
            />
          </template>
          <template #default="{ isActive }">
            <charges-list-detail
              :charge="item"
              @close="isActive.value = false"
            />
          </template>
        </v-dialog>
        <v-btn
          icon="mdi-printer"
          size="small"
          class="me-2"
          @click="downloadPatientPDF(item)"
        />
        <v-snackbar
          v-model="pdfDownloadSnackbarAlert"
          :timeout="2000"
          color="primary"
          rounded="pill"
        >
          Se está generando el PDF, puede tomar unos segundos...
        </v-snackbar>
        <v-dialog
          v-if="item.patient.representative.email"
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              v-bind="activatorProps"
              icon="mdi-email-arrow-right"
              size="small"
              class="me-2"
            >
              <v-icon icon="mdi-email-arrow-right" />
              <v-tooltip
                v-if="item.lastEmailSentAt"
                activator="parent"
                location="top"
              >
                Enviado hace {{ item.lastEmailSentAt }}
              </v-tooltip>
            </v-btn>
          </template>

          <template #default="{ isActive }">
            <v-card>
              <v-card-title class="ml-2 align-baseline d-flex">
                Enviar correo de cobro
                <v-spacer />
                <v-btn
                  icon="mdi-window-close"
                  variant="plain"
                  @click="isActive.value = false"
                />
              </v-card-title>
              <v-card-text class="mt-4">
                Estás a punto de enviar un correo con el cobro correspondiente al
                paciente <b>{{ item.patient.fullName }}</b> desde el <b>{{ localizedDate(filters.fromDate) }}</b>
                hasta el <b>{{ localizedDate(filters.toDate) }}</b>.
              </v-card-text>
              <v-card-text>
                Será enviado al apoderado {{ item.patient.representative.fullName }},
                al correo <b>{{ item.patient.representative.email }}</b>.
              </v-card-text>
              <v-card-text v-if="item.lastEmailSentAt">
                El último correo de cobro enviado para este paciente fue <b>hace {{ item.lastEmailSentAt }}</b>.
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  text="Enviar"
                  color="primary"
                  @click="isActive.value = false; sendPatientChargesViaEmail(item)"
                />
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-snackbar
          v-model="emailSnackbarAlert"
          :timeout="2000"
          color="primary"
          rounded="pill"
        >
          Se está enviando el correo, puede tomar unos segundos...
        </v-snackbar>
        <v-btn
          v-if="item.patient.representative.phoneNumber"
          icon="mdi-whatsapp"
          size="small"
          class="me-2"
          :disabled="isCreatingChargePdf"
          @click="sendWhatsappMessage(item)"
        />
        <v-snackbar
          v-model="whatsappSnackbarAlert"
          color="primary"
          rounded="pill"
        >
          Generando mensaje de WhatsApp...
        </v-snackbar>
        <v-snackbar
          v-model="errorSnackbarAlert"
          :timeout="3000"
          color="error"
          rounded="pill"
        >
          {{ errorMessage }}
        </v-snackbar>
      </template>
      <template
        v-if="!search"
        #body.append
      >
        <tr>
          <th class="font-weight-bold">
            Total
          </th>
          <th>
            -
          </th>
          <th class="font-weight-bold">
            {{ totalShiftsCount }}
          </th>
          <th class="font-weight-bold">
            {{ formatCurrency(totalPrice) }}
          </th>
          <th class="font-weight-bold">
            {{ formatCurrency(totalChargeMovements) }}
          </th>
          <th>
            <v-chip color="green-darken-3">
              {{ formatCurrency(totalAmountPendingToCharge) }}
            </v-chip>
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
