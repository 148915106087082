<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { servicePricesApi } from '@/api/service-prices';
import { formatCurrency } from '@/utils/currency';
import PatientServicePriceForm from './patient-service-price-form.vue';

const props = defineProps<{
  patientId: number;
}>();

const { data: servicePrices, isLoading } = useQuery({
  queryKey: ['servicePrices'],
  queryFn: () => servicePricesApi.index(props.patientId),
  initialData: [],
});
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <span>Precios de Servicios del Paciente</span>
        <v-dialog
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              color="primary"
              v-bind="activatorProps"
            >
              Agregar
            </v-btn>
          </template>
          <template #default="{ isActive }">
            <patient-service-price-form
              :patient-id="props.patientId"
              @close="isActive.value = false"
            />
          </template>
        </v-dialog>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="[
            { title: 'Servicio', key: 'humanServiceType' },
            { title: 'Precio Semana', key: 'weekPrice' },
            { title: 'Pago Semana (líquido)', key: 'weekCost' },
            { title: 'Precio Fin de Semana', key: 'weekendPrice' },
            { title: 'Pago Fin de Semana (líquido)', key: 'weekendCost' },
            { title: 'Con boleta de honorarios', key: 'withTaxBill' },
            { title: 'Acciones', key: 'actions', sortable: false }
          ]"
          :items="servicePrices"
          :loading="isLoading"
          class="mt-8"
          loading-text="Cargando los precios de servicios del paciente..."
          no-data-text="No hay precios de servicios del paciente"
        >
          <template #[`item.weekPrice`]="{ item }">
            {{ formatCurrency(item.weekPrice) }}
          </template>
          <template #[`item.weekCost`]="{ item }">
            {{ formatCurrency(item.weekCost) }}
          </template>
          <template #[`item.weekendPrice`]="{ item }">
            {{ formatCurrency(item.weekendPrice) }}
          </template>
          <template #[`item.weekendCost`]="{ item }">
            {{ formatCurrency(item.weekendCost) }}
          </template>
          <template #[`item.withTaxBill`]="{ item }">
            <v-chip :color="item.withTaxBill ? 'green' : 'red'">
              {{ item.withTaxBill ? 'Sí' : 'No' }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-dialog
              max-width="800"
              scrollable
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  v-bind="activatorProps"
                  icon="mdi-pencil"
                  size="small"
                  class="me-2"
                />
              </template>
              <template #default="{ isActive }">
                <patient-service-price-form
                  :patient-id="props.patientId"
                  :service-price="item"
                  @close="isActive.value = false"
                />
              </template>
            </v-dialog>
          </template>
          <template #bottom />
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
