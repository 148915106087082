<script setup lang="ts">
import { computed, inject, ref, watch, type PropType, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import { type Caregiver } from '@/api/caregivers';
import { type Patient } from '@/api/patients';
import { shiftsApi, type ShiftRecurrentCreateForm } from '@/api/shifts';
import type { AxiosError } from 'axios';
import { type SerializedUser } from '@/api/users';
import PriceInput from '@/components/price-input.vue';

const { t } = useI18n();

const emit = defineEmits(['close', 'refetch', 'success']);

const currentUser = inject<Ref<SerializedUser>>('currentUser');

const props = defineProps({
  patient: {
    type: Object as PropType<Patient> || undefined,
    default: undefined,
  },
  caregiver: {
    type: Object as PropType<Caregiver> || undefined,
    default: undefined,
  },
});

const queryClient = useQueryClient();
const caregivers = queryClient.getQueryData<Caregiver[]>(['caregivers']);
const patients = queryClient.getQueryData<Patient[]>(['patients']);

const commonValidationSchema = {
  patientId: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  caregiverId: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  withTaxBill: yup.boolean().required(t('shiftRecurrentCreateForm.fieldRequired')),
  startDate: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  endDate: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  shiftType: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  startTime: yup.object({
    hours: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired')),
    minutes: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired')),
  }),
  endTime: yup.object({
    hours: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired')),
    minutes: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired')),
  }),
  recurrenceType: yup.string().required(t('shiftRecurrentCreateForm.fieldRequired')),
  recurrenceInterval: yup.number()
    .min(1, t('shiftRecurrentCreateForm.fieldPositive'))
    .required(t('shiftRecurrentCreateForm.fieldRequired')),
  recurrenceDays: yup.array().of(yup.string()).when('recurrenceType', {
    is: 'weekly',
    then: (schema) => schema.required(t('shiftRecurrentCreateForm.fieldRequired')),
    otherwise: (schema) => schema.notRequired(),
  }),
};

const normalValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverWeekShiftCost: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  caregiverWeekendShiftCost: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  weekShiftPrice: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  weekendShiftPrice: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
});

const perHourValidationSchema = yup.object({
  ...commonValidationSchema,
  caregiverWeekShiftCostPerHour: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  caregiverWeekendShiftCostPerHour: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  weekShiftPricePerHour: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
  weekendShiftPricePerHour: yup.number().required(t('shiftRecurrentCreateForm.fieldRequired'))
    .typeError(t('shiftRecurrentCreateForm.fieldRequired')),
});

const validationSchema = currentUser?.value.companyChargesAndPaysPerHours ?
  perHourValidationSchema : normalValidationSchema;

const commonInitialValues = {
  patientId: props.patient?.id,
  caregiverId: props.caregiver?.id,
  withTaxBill: props.patient?.lastWeekShift?.withTaxBill || props.patient?.lastWeekendShift?.withTaxBill,
  shiftType: undefined,
  startTime: undefined,
  endTime: undefined,
  startDate: undefined,
  endDate: undefined,
  recurrenceType: undefined,
  recurrenceInterval: 1,
  recurrenceDays: undefined,
};

const normalInitialValues = {
  ...commonInitialValues,
  caregiverWeekShiftCost: props.patient?.lastWeekShift?.caregiverCost || 0,
  caregiverWeekendShiftCost: props.patient?.lastWeekendShift?.caregiverCost || 0,
  weekShiftPrice: props.patient?.lastWeekShift?.price || 0,
  weekendShiftPrice: props.patient?.lastWeekendShift?.price || 0,
};

const perHourInitialValues = {
  ...commonInitialValues,
  caregiverWeekShiftCostPerHour: props.patient?.lastWeekShift?.caregiverCostPerHour || 0,
  caregiverWeekendShiftCostPerHour: props.patient?.lastWeekendShift?.caregiverCostPerHour || 0,
  weekShiftPricePerHour: props.patient?.lastWeekShift?.pricePerHour || 0,
  weekendShiftPricePerHour: props.patient?.lastWeekendShift?.pricePerHour || 0,
};

const initialValues = currentUser?.value.companyChargesAndPaysPerHours ? perHourInitialValues : normalInitialValues;

const { handleSubmit, values, errors, defineField } = useForm<ShiftRecurrentCreateForm>({
  validationSchema, initialValues,
});

const [patientId, patientIdAttrs] = defineField('patientId');
const [caregiverId, caregiverIdAttrs] = defineField('caregiverId');
const [shiftType, shiftTypeAttrs] = defineField('shiftType');
const [startTime, startTimeAttrs] = defineField('startTime');
const [endTime, endTimeAttrs] = defineField('endTime');
const [startDate, startDateAttrs] = defineField('startDate');
const [endDate, endDateAttrs] = defineField('endDate');
const [recurrenceType, recurrenceTypeAttrs] = defineField('recurrenceType');
const [recurrenceInterval, recurrenceIntervalAttrs] = defineField('recurrenceInterval');
const [recurrenceDays, recurrenceDaysAttrs] = defineField('recurrenceDays');
const [caregiverWeekShiftCost, caregiverWeekShiftCostAttrs] = defineField('caregiverWeekShiftCost');
const [caregiverWeekendShiftCost, caregiverWeekendShiftCostAttrs] = defineField('caregiverWeekendShiftCost');
const [weekShiftPrice, weekShiftPriceAttrs] = defineField('weekShiftPrice');
const [weekendShiftPrice, weekendShiftPriceAttrs] = defineField('weekendShiftPrice');
const [caregiverWeekShiftCostPerHour, caregiverWeekShiftCostPerHourAttrs] = defineField(
  'caregiverWeekShiftCostPerHour');
const [caregiverWeekendShiftCostPerHour, caregiverWeekendShiftCostPerHourAttrs] = defineField(
  'caregiverWeekendShiftCostPerHour');
const [weekShiftPricePerHour, weekShiftPricePerHourAttrs] = defineField('weekShiftPricePerHour');
const [weekendShiftPricePerHour, weekendShiftPricePerHourAttrs] = defineField('weekendShiftPricePerHour');
const [withTaxBill, withTaxBillAttrs] = defineField('withTaxBill');

const { mutate, isIdle, isError, isPending, isSuccess } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => shiftsApi.create({ ...values, recurrent: true }),
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ['patients'] });
      emit('refetch');
      emit('success');
    },
  },
);

const submitButtonDisabled = ref(false);
const onSubmit = handleSubmit(() => {
  submitButtonDisabled.value = true;
  mutate();
});

watch(shiftType, () => {
  if (shiftType.value === 'day') {
    startTime.value = { hours: 8, minutes: 0 };
    endTime.value = { hours: 20, minutes: 0 };
  } else if (shiftType.value === 'night') {
    startTime.value = { hours: 20, minutes: 0 };
    endTime.value = { hours: 8, minutes: 0 };
  } else if (shiftType.value === '24') {
    endTime.value = startTime.value;
  }
});
watch(startTime, () => {
  if (shiftType.value === '24') {
    endTime.value = startTime.value;
  }
});

watch(recurrenceType, () => {
  if (recurrenceType.value === 'daily') {
    recurrenceDays.value = undefined;
  } else if (recurrenceType.value === 'weekly') {
    recurrenceInterval.value = 1;
  }
});

const days = [
  { title: 'Lunes', value: 'monday' }, { title: 'Martes', value: 'tuesday' },
  { title: 'Miércoles', value: 'wednesday' }, { title: 'Jueves', value: 'thursday' },
  { title: 'Viernes', value: 'friday' }, { title: 'Sábado', value: 'saturday' }, { title: 'Domingo', value: 'sunday' },
];

const recurrences = [
  { title: 'Diaria', value: 'daily' },
  { title: 'Semanal', value: 'weekly' },
];
const dailyRecurrenceOptions = [
  { title: 'Todos los días', value: 1 },
  { title: 'Día por medio', value: 2 },
  { title: 'Cada 3 días', value: 3 },
  { title: 'Cada 4 días', value: 4 },
  { title: 'Cada 5 días', value: 5 },
  { title: 'Cada 6 días', value: 6 },
];

const steps = ['Información General', 'Precios', 'Recurrencia'];

watch(isSuccess, async () => {
  if (isSuccess.value === true) {
    const waitMs = 1500;
    await new Promise((resolve) => setTimeout(resolve, waitMs));
    emit('close');
  }
});

function isFilled(value: boolean | string | number | null | undefined) {
  return (value !== '' && value !== null && value !== undefined);
}
function isTimeFilled(value: { hours: number, minutes: number } | null | undefined) {
  return (value !== null && value !== undefined);
}
const currentStep = ref(0);
const isFirstStepCompleted = computed(() =>
  isFilled(patientId.value) &&
  isFilled(caregiverId.value) &&
  (isTimeFilled(startTime.value) && isTimeFilled(endTime.value)),
);
const isSecondStepPerHourCompleted = computed(() =>
  isFilled(caregiverWeekShiftCostPerHour.value) &&
  isFilled(caregiverWeekendShiftCostPerHour.value) &&
  isFilled(weekShiftPricePerHour.value) &&
  isFilled(weekendShiftPricePerHour.value) &&
  isFilled(withTaxBill.value),
);
const isSecondStepCompleted = computed(() => {
  if (currentUser?.value.companyChargesAndPaysPerHours) return isSecondStepPerHourCompleted.value;

  return isFilled(caregiverWeekShiftCost.value) &&
    isFilled(caregiverWeekendShiftCost.value) &&
    isFilled(weekShiftPrice.value) &&
    isFilled(weekendShiftPrice.value) &&
    isFilled(withTaxBill.value);
});
const isDailyRecurrenceCompleted = computed(() =>
  recurrenceType.value === 'daily' &&
  isFilled(recurrenceInterval.value),
);
const isWeeklyRecurrenceCompleted = computed(() =>
  recurrenceType.value === 'weekly' &&
  recurrenceDays.value?.length && recurrenceDays.value?.length > 0,
);
const isLastStepCompleted = computed(() =>
  isFilled(startDate.value) &&
  isFilled(endDate.value) &&
  isFilled(recurrenceType.value) &&
  (isDailyRecurrenceCompleted.value || isWeeklyRecurrenceCompleted.value),
);
// eslint-disable-next-line complexity
const canGoToNextStep = computed(() => {
  if (currentStep.value === 0 && !isFirstStepCompleted.value) return false;
  if (currentStep.value === 1 && !isSecondStepCompleted.value) return false;
  if (currentStep.value === steps.length - 1 && !isLastStepCompleted.value) return false;

  return true;
});

const nextYear = new Date().getFullYear() + 1;
const maxDate = `${nextYear}-12-31`;
watch(startDate, (newValue) => {
  if (newValue) {
    const selectedDate = new Date(newValue);
    const limitDate = new Date(maxDate);
    if (selectedDate > limitDate) {
      startDate.value = maxDate;
    }
  }
});
watch(endDate, (newValue) => {
  if (newValue) {
    const selectedDate = new Date(newValue);
    const limitDate = new Date(maxDate);
    if (selectedDate > limitDate) {
      endDate.value = maxDate;
    }
  }
});

function cleanShiftValues() {
  caregiverWeekShiftCost.value = 0;
  weekShiftPrice.value = 0;
  caregiverWeekendShiftCost.value = 0;
  weekendShiftPrice.value = 0;
  caregiverWeekShiftCostPerHour.value = 0;
  weekShiftPricePerHour.value = 0;
  caregiverWeekendShiftCostPerHour.value = 0;
  weekendShiftPricePerHour.value = 0;
  withTaxBill.value = false;
}
function setWeekShiftValues(patient: Patient) {
  caregiverWeekShiftCost.value = patient.lastWeekShift.caregiverCost || 0;
  caregiverWeekShiftCostPerHour.value = patient.lastWeekShift.caregiverCostPerHour || 0;
  weekShiftPrice.value = patient.lastWeekShift.price || 0;
  weekShiftPricePerHour.value = patient.lastWeekShift.pricePerHour || 0;
  withTaxBill.value = patient.lastWeekShift.withTaxBill;
}
function setWeekendShiftValues(patient: Patient) {
  caregiverWeekendShiftCost.value = patient.lastWeekendShift.caregiverCost || 0;
  caregiverWeekendShiftCostPerHour.value = patient.lastWeekendShift.caregiverCostPerHour || 0;
  weekendShiftPrice.value = patient.lastWeekendShift.price || 0;
  weekendShiftPricePerHour.value = patient.lastWeekendShift.pricePerHour || 0;
  withTaxBill.value = patient.lastWeekendShift.withTaxBill;
}
function prefillShiftValues() {
  if (patientId.value) {
    const patient = patients?.find(p => p.id === patientId.value);
    if (patient?.lastWeekShift) setWeekShiftValues(patient);
    if (patient?.lastWeekendShift) setWeekendShiftValues(patient);
  }
}

watch(patientId, () => {
  cleanShiftValues();
  prefillShiftValues();
});
</script>
<template>
  <v-card
    class="v-col-12 v-col-md-10 pa-4"
    flat
  >
    <v-card-title class="mb-4 ml-2 align-baseline d-flex">
      {{ t('shiftRecurrentCreateForm.title') }}
      <v-spacer />
      <v-btn
        icon="mdi-window-close"
        variant="plain"
        @click="$emit('close')"
      />
    </v-card-title>
    <v-form
      v-if="isIdle"
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-stepper
        v-model="currentStep"
        flat
      >
        <template #default="{ prev, next }">
          <v-stepper-header>
            <template
              v-for="(step, index) in steps"
              :key="`${step}-step`"
            >
              <v-stepper-item
                :step="step"
                :value="index"
                icon="mdi-form-select"
                :title="steps[index]"
                :complete="index < currentStep"
                :color="index <= currentStep ? 'primary' : 'gray'"
              />

              <v-divider
                v-if="index !== steps.length - 1"
                :key="index"
              />
            </template>
          </v-stepper-header>
          <v-stepper-window>
            <v-stepper-window-item>
              <v-row class="mx-2 mt-4 d-flex">
                <v-autocomplete
                  v-model="patientId"
                  v-bind="patientIdAttrs"
                  class="px-2 v-col-6"
                  variant="outlined"
                  item-title="fullName"
                  item-value="id"
                  :label="t('shift.patient')"
                  :error-messages="errors.patientId"
                  :items="patients"
                />
                <v-autocomplete
                  v-model="caregiverId"
                  v-bind="caregiverIdAttrs"
                  class="px-2 v-col-6"
                  variant="outlined"
                  item-title="fullName"
                  item-value="id"
                  :label="t('shift.caregiver')"
                  :error-messages="errors.caregiverId"
                  :items="caregivers"
                />
              </v-row>
              <v-select
                v-model="shiftType"
                v-bind="shiftTypeAttrs"
                :label="t('shiftForm.shiftTypeTitle')"
                :error-messages="errors.shiftType"
                :items="[
                  { title: t('shiftForm.shiftTypeDay'), value: 'day' },
                  { title: t('shiftForm.shiftTypeNight'), value: 'night' },
                  { title: t('shiftForm.shiftType24'), value: '24' },
                  { title: t('shiftForm.shiftTypeCustom'), value: 'custom' }
                ]"
                variant="outlined"
                class="px-4 v-col-6"
              />
              <v-col
                v-if="shiftType === '24'"
                class="mb-4 d-flex flex-column"
              >
                <p class="ml-6 mb-n3 text-caption text-grey">
                  {{ t('shiftForm.shiftType24Info') }}
                </p>
                <vue-date-picker
                  v-model="startTime"
                  v-bind="startTimeAttrs"
                  class="w-50 pa-4"
                  time-picker
                  auto-apply
                  minutes-increment="15"
                  minutes-grid-increment="15"
                  placeholder="Hora Inicio"
                  teleport-center
                />
              </v-col>
              <div
                v-if="shiftType === 'custom'"
                class="mb-4 d-flex"
              >
                <v-col>
                  <p class="ml-6 mb-n3 text-caption text-grey">
                    {{ t('shiftForm.shiftTypeCustomStartInfo') }}
                  </p>
                  <vue-date-picker
                    v-model="startTime"
                    v-bind="startTimeAttrs"
                    class="w-75 pa-4"
                    time-picker
                    auto-apply
                    minutes-increment="15"
                    minutes-grid-increment="15"
                    placeholder="Hora Inicio"
                    teleport-center
                  />
                </v-col>
                <v-col>
                  <p class="ml-6 mb-n3 text-caption text-grey">
                    {{ t('shiftForm.shiftTypeCustomEndInfo') }}
                  </p>
                  <vue-date-picker
                    v-model="endTime"
                    v-bind="endTimeAttrs"
                    class="w-75 pa-4"
                    time-picker
                    auto-apply
                    minutes-increment="15"
                    minutes-grid-increment="15"
                    placeholder="Hora Término"
                    teleport-center
                  />
                </v-col>
              </div>
            </v-stepper-window-item>
            <v-stepper-window-item>
              <v-card
                :title="t('shiftBatchCreateForm.priceTitle')"
                flat
              >
                <v-row
                  v-if="currentUser?.companyChargesAndPaysPerHours"
                  class="mx-2 mt-4 mb-2 d-flex"
                >
                  <price-input
                    v-model="weekShiftPricePerHour"
                    v-bind="weekShiftPricePerHourAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.weekShiftPricePerHour')"
                    :error-messages="errors.weekShiftPricePerHour"
                  />
                  <price-input
                    v-model="weekendShiftPricePerHour"
                    v-bind="weekendShiftPricePerHourAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.weekendShiftPricePerHour')"
                    :error-messages="errors.weekendShiftPricePerHour"
                  />
                </v-row>
                <v-row
                  v-else
                  class="mx-2 mt-4 mb-2 d-flex"
                >
                  <price-input
                    v-model="weekShiftPrice"
                    v-bind="weekShiftPriceAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.weekShiftPrice')"
                    :error-messages="errors.weekShiftPrice"
                  />
                  <price-input
                    v-model="weekendShiftPrice"
                    v-bind="weekendShiftPriceAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.weekendShiftPrice')"
                    :error-messages="errors.weekendShiftPrice"
                  />
                </v-row>
              </v-card>
              <v-card
                :title="t('shiftBatchCreateForm.caregiverCostTitle')"
                flat
              >
                <v-row
                  v-if="currentUser?.companyChargesAndPaysPerHours"
                  class="mx-2 mt-4 mb-2 d-flex"
                >
                  <price-input
                    v-model="caregiverWeekShiftCostPerHour"
                    v-bind="caregiverWeekShiftCostPerHourAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.caregiverWeekShiftCostPerHour')"
                    :error-messages="errors.caregiverWeekShiftCostPerHour"
                  />
                  <price-input
                    v-model="caregiverWeekendShiftCostPerHour"
                    v-bind="caregiverWeekendShiftCostPerHourAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.caregiverWeekendShiftCostPerHour')"
                    :error-messages="errors.caregiverWeekendShiftCostPerHour"
                  />
                </v-row>
                <v-row
                  v-else
                  class="mx-2 mt-4 mb-2 d-flex"
                >
                  <price-input
                    v-model="caregiverWeekShiftCost"
                    v-bind="caregiverWeekShiftCostAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.caregiverWeekShiftCost')"
                    :error-messages="errors.caregiverWeekShiftCost"
                  />
                  <price-input
                    v-model="caregiverWeekendShiftCost"
                    v-bind="caregiverWeekendShiftCostAttrs"
                    class="px-2 v-col-6"
                    :label="t('shiftBatchCreateForm.caregiverWeekendShiftCost')"
                    :error-messages="errors.caregiverWeekendShiftCost"
                  />
                </v-row>
              </v-card>
              <v-radio-group
                v-model="withTaxBill"
                v-bind="withTaxBillAttrs"
                :label="t('shiftBatchCreateForm.withTaxBillTitle')"
                :error-messages="errors.withTaxBill"
                inline
              >
                <v-radio
                  :label="t('shiftBatchCreateForm.yes')"
                  :value="true"
                />
                <v-radio
                  :label="t('shiftBatchCreateForm.no')"
                  :value="false"
                />
              </v-radio-group>
            </v-stepper-window-item>
            <v-stepper-window-item>
              <v-row class="mx-2 mt-4 d-flex">
                <v-text-field
                  v-model="startDate"
                  v-bind="startDateAttrs"
                  class="px-2 v-col-6"
                  type="date"
                  label="Fecha desde"
                  variant="outlined"
                  :error-messages="errors.startDate"
                  :max="maxDate"
                />
                <v-text-field
                  v-model="endDate"
                  v-bind="endDateAttrs"
                  class="px-2 v-col-6"
                  type="date"
                  label="Fecha hasta"
                  variant="outlined"
                  :error-messages="errors.endDate"
                  :max="maxDate"
                />
              </v-row>
              <v-row class="mx-2 mt-4 d-flex">
                <v-select
                  v-model="recurrenceType"
                  v-bind="recurrenceTypeAttrs"
                  class="px-2 v-col-6"
                  variant="outlined"
                  :items="recurrences"
                  :label="t('shiftRecurrentCreateForm.recurrenceType')"
                  :error-messages="errors.recurrenceType"
                />
                <v-select
                  v-if="recurrenceType === 'daily'"
                  v-model="recurrenceInterval"
                  v-bind="recurrenceIntervalAttrs"
                  class="px-2 v-col-6"
                  variant="outlined"
                  :items="dailyRecurrenceOptions"
                  :label="t('shiftRecurrentCreateForm.recurrenceInterval')"
                  :error-messages="errors.recurrenceInterval"
                />
                <v-autocomplete
                  v-if="recurrenceType === 'weekly'"
                  v-model="recurrenceDays"
                  v-bind="recurrenceDaysAttrs"
                  :items="days"
                  :label="t('shiftRecurrentCreateForm.recurrenceDays')"
                  :error-messages="errors.recurrenceDays"
                  class="px-2 v-col-6"
                  variant="outlined"
                  :menu-props="{ maxHeight: '400' }"
                  chips
                  multiple
                  clear-on-select
                />
              </v-row>
            </v-stepper-window-item>
          </v-stepper-window>
          <v-stepper-actions
            :class="currentStep === 0 ? 'justify-end' : 'justify-between'"
            :disabled="canGoToNextStep ? false : 'next'"
          >
            <template #prev>
              <v-btn
                v-if="currentStep !== 0"
                @click="prev"
              >
                {{ t('shiftRecurrentCreateForm.previous') }}
              </v-btn>
            </template>
            <template #next>
              <v-btn
                v-if="currentStep !== steps.length - 1"
                class="align-self-end"
                color="primary"
                @click="next"
              >
                {{ t('shiftRecurrentCreateForm.next') }}
              </v-btn>
              <v-btn
                v-else
                variant="flat"
                color="primary"
                type="submit"
                :disabled="submitButtonDisabled"
              >
                {{ t('shiftBatchCreateForm.submit') }}
              </v-btn>
            </template>
          </v-stepper-actions>
        </template>
      </v-stepper>
    </v-form>
    <v-col
      v-if="isPending"
      class="pb-20"
    >
      <v-row class="justify-center d-flex">
        <v-progress-circular
          class="mx-auto"
          indeterminate
          color="primary"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Estamos creando los turnos, esto puede tomar unos segundos...</p>
      </v-row>
    </v-col>
    <v-col v-if="isSuccess">
      <v-row class="justify-center d-flex">
        <v-icon
          icon="mdi-check-circle"
          color="primary"
          size="50"
        />
      </v-row>
      <v-row class="justify-center mt-8 d-flex">
        <p>Turnos creados exitosamente!</p>
      </v-row>
    </v-col>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
