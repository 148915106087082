import { computed, type Ref } from 'vue';

interface DateFilters {
  fromDate: string;
  toDate: string;
}

export function useDateRangeValidation(filters: Ref<DateFilters>) {
  const formattedFromDate = computed(() => new Date(filters.value.fromDate.replace(/-/g, '/')));
  const formattedToDate = computed(() => new Date(filters.value.toDate.replace(/-/g, '/')));

  const isValidDateRange = computed(() => {
    if (!filters.value.fromDate || !filters.value.toDate) return false;

    const fromDate = formattedFromDate.value;
    const toDate = formattedToDate.value;

    return fromDate <= toDate && fromDate.getFullYear() === toDate.getFullYear();
  });

  const dateRangeError = computed(() => {
    if (!filters.value.fromDate || !filters.value.toDate) return '';

    const fromDate = formattedFromDate.value;
    const toDate = formattedToDate.value;

    if (fromDate > toDate) {
      return 'La fecha inicial debe ser menor o igual a la fecha final.';
    }
    if (fromDate.getFullYear() !== toDate.getFullYear()) {
      return 'Las fechas deben ser del mismo año.';
    }

    return '';
  });

  return {
    isValidDateRange,
    dateRangeError,
  };
}
