<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { baseServicePricesApi } from '@/api/base-service-prices';
import { formatCurrency } from '@/utils/currency';
import BaseServicePriceForm from './base-service-price-form.vue';

const { data: baseServicePrices, isLoading } = useQuery({
  queryKey: ['baseServicePrices'],
  queryFn: baseServicePricesApi.index,
  initialData: [],
});
</script>

<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <span>Precios Base de Servicios</span>
        <v-dialog
          max-width="800"
          scrollable
        >
          <template #activator="{ props: activatorProps }">
            <v-btn
              color="primary"
              v-bind="activatorProps"
            >
              Agregar
            </v-btn>
          </template>
          <template #default="{ isActive }">
            <base-service-price-form
              @close="isActive.value = false"
            />
          </template>
        </v-dialog>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="[
            { title: 'Servicio', key: 'humanServiceType' },
            { title: 'Precio Semana', key: 'weekPrice' },
            { title: 'Pago Semana (líquido)', key: 'weekCost' },
            { title: 'Precio Fin de Semana', key: 'weekendPrice' },
            { title: 'Pago Fin de Semana (líquido)', key: 'weekendCost' },
            { title: 'Con boleta de honorarios', key: 'withTaxBill' },
            { title: 'Acciones', key: 'actions', sortable: false }
          ]"
          :items="baseServicePrices"
          :loading="isLoading"
          class="mt-8"
          loading-text="Cargando los precios base de servicios..."
          no-data-text="No hay precios base de servicios creados"
        >
          <template #[`item.weekPrice`]="{ item }">
            {{ formatCurrency(item.weekPrice) }}
          </template>
          <template #[`item.weekCost`]="{ item }">
            {{ formatCurrency(item.weekCost) }}
          </template>
          <template #[`item.weekendPrice`]="{ item }">
            {{ formatCurrency(item.weekendPrice) }}
          </template>
          <template #[`item.weekendCost`]="{ item }">
            {{ formatCurrency(item.weekendCost) }}
          </template>
          <template #[`item.withTaxBill`]="{ item }">
            <v-chip :color="item.withTaxBill ? 'green' : 'red'">
              {{ item.withTaxBill ? 'Sí' : 'No' }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-dialog
              max-width="800"
              scrollable
            >
              <template #activator="{ props: activatorProps }">
                <v-btn
                  v-bind="activatorProps"
                  icon="mdi-pencil"
                  size="small"
                  class="me-2"
                />
              </template>
              <template #default="{ isActive }">
                <base-service-price-form
                  :base-service-price="item"
                  @close="isActive.value = false"
                />
              </template>
            </v-dialog>
          </template>
          <template #bottom />
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
