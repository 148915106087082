<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useUser } from '@/composables/useUser';

const { user, logout } = useUser();

const currentSection = ref('');

// eslint-disable-next-line complexity
onBeforeMount(() => {
  if (window.location.pathname.includes('caregivers')) {
    currentSection.value = 'caregivers';
  } else if (window.location.pathname.includes('patients')) {
    currentSection.value = 'patients';
  } else if (window.location.pathname.includes('payments')) {
    currentSection.value = 'payments';
  } else if (window.location.pathname.includes('charges')) {
    currentSection.value = 'charges';
  } else if (window.location.pathname.includes('today_shifts')) {
    currentSection.value = 'today_shifts';
  } else if (window.location.pathname.includes('settings')) {
    currentSection.value = 'settings';
  } else {
    currentSection.value = 'shifts';
  }
});
</script>
<template>
  <v-app-bar :elevation="2">
    <a
      style="width: 150px;"
      href="/"
    >
      <v-img
        class="mx-2"
        src="@assets/images/logo.png"
        max-height="100"
        max-width="100"
        contain
      />
    </a>
    <v-list
      v-if="user"
      class="d-flex"
      color="transparent"
    >
      <v-list-item
        title="Hoy"
        class="rounded-pill"
        href="/today_shifts"
        active-class="text-primary"
        :active="currentSection === 'today_shifts'"
      />
      <v-list-item
        title="Turnos"
        class="rounded-pill"
        href="/shifts"
        active-class="text-primary"
        :active="currentSection === 'shifts'"
      />
      <v-list-item
        title="Profesionales"
        class="rounded-pill"
        href="/caregivers"
        active-class="text-primary"
        :active="currentSection === 'caregivers'"
      />
      <v-list-item
        title="Pacientes"
        class="rounded-pill"
        href="/patients"
        active-class="text-primary"
        :active="currentSection === 'patients'"
      />
      <v-list-item
        title="Cobros"
        class="rounded-pill"
        href="/charges"
        active-class="text-primary"
        :active="currentSection === 'charges'"
      />
      <v-list-item
        title="Pagos"
        class="rounded-pill"
        href="/payments"
        active-class="text-primary"
        :active="currentSection === 'payments'"
      />
      <v-list-item
        v-if="user?.companyProfessionalViewEnabled"
        title="Configuración"
        class="rounded-pill"
        href="/settings"
        active-class="text-primary"
        :active="currentSection === 'settings'"
      />
    </v-list>
    <v-spacer />
    <v-menu v-if="user">
      <template #activator="{ props }">
        <v-btn
          icon="mdi-account-circle"
          size="large"
          color="primary"
          v-bind="props"
        />
      </template>
      <v-list>
        <v-list-item class="text-body-2">
          <v-icon icon="mdi-email-outline" />
          {{ user?.email }}
        </v-list-item>
        <v-list-item class="text-body-2">
          <v-icon icon="mdi-domain" />
          {{ user?.companyName }}
        </v-list-item>
        <v-list-item>
          <v-btn
            block
            @click="logout"
          >
            Cerrar Sesión
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
