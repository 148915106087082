import { api } from './index';

const BASE_PATH = '/api/internal/payment_pdfs';

export type PaymentPdf = {
  publicId: string;
}

export interface PaymentPdfForm {
  caregiverId: number;
  fromDate: string;
  toDate: string;
}

export const paymentPdfsApi = {
  create(paymentPdfForm: PaymentPdfForm): Promise<PaymentPdf> {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { paymentPdf: paymentPdfForm },
    }).then(response => response.data.paymentPdf);
  },
};
