<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { chargeMovementsApi, type ChargeMovementForm } from '@/api/charge-movements';
import { useChargesStore } from '@/stores/charges';
import * as yup from 'yup';
import type { AxiosError } from 'axios';
import PriceInput from '@/components/price-input.vue';

const { t } = useI18n();

interface Props {
  chargeMovement?: ChargeMovementForm | undefined;
  patientId: number;
  mode: 'new' | 'edit';
}
const props = defineProps<Props>();

const emit = defineEmits(['close']);

const store = useChargesStore();

const validationSchema = yup.object({
  date: yup.date()
    .min(store.fromDate, t('chargeMovementForm.validDateRange'))
    .max(store.toDate, t('chargeMovementForm.validDateRange'))
    .required(t('chargeMovementForm.fieldRequired')),
  movementType: yup.string().required(t('chargeMovementForm.fieldRequired')),
  amount: yup.number()
    .typeError(t('chargeMovementForm.fieldPositive'))
    .min(1, t('chargeMovementForm.fieldPositive'))
    .required(t('chargeMovementForm.fieldRequired')),
  description: yup.string().required(t('chargeMovementForm.fieldRequired')),
});

const initialValues = {
  patientId: props.patientId,
  date: props.chargeMovement?.date,
  movementType: props.chargeMovement?.movementType,
  amount: props.chargeMovement?.amount ? Math.abs(props.chargeMovement.amount) : 0,
  description: props.chargeMovement?.description,
};

const { handleSubmit, values, errors, defineField } = useForm<ChargeMovementForm>({ validationSchema, initialValues });
const [date, dateAttrs] = defineField('date');
const [movementType, movementTypeAttrs] = defineField('movementType');
const [amount, amountAttrs] = defineField('amount');
const [description, descriptionAttrs] = defineField('description');

const queryClient = useQueryClient();
const { mutate: createChargeMovement, isError: isCreateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => chargeMovementsApi.create({ ...values }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['charges'] });
      emit('close');
    },
  },
);
const { mutate: updateChargeMovement, isError: isUpdateError } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => chargeMovementsApi.update({ ...values, id: props.chargeMovement?.id }),
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['charges'] });
      emit('close');
    },
  },
);

const submitButtonDisabled = ref(false);
const onSubmit = handleSubmit(() => {
  submitButtonDisabled.value = true;
  if (props.mode === 'edit') {
    updateChargeMovement();
  } else {
    createChargeMovement();
  }
});
const movementTypes = [
  { title: 'Restar 💰 al paciente', value: 'refund' },
  { title: 'Sumar 💰 al paciente', value: 'adjustment' },
];
</script>
<template>
  <v-card class="px-4 pb-4">
    <v-card-title class="mb-4 align-baseline d-flex">
      {{ mode === 'new' ? t('chargeMovementForm.createTitle') : t('chargeMovementForm.updateTitle') }}
      <v-spacer />
      <v-btn
        icon="mdi-window-close"
        variant="plain"
        @click="emit('close')"
      />
    </v-card-title>
    <v-form
      class="w-100 ma-0"
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-select
          v-model="movementType"
          v-bind="movementTypeAttrs"
          class="px-2 v-col-6"
          variant="outlined"
          :items="movementTypes"
          :label="t('chargeMovement.movementType')"
          :error-messages="errors.movementType"
        />
        <v-text-field
          v-model="description"
          v-bind="descriptionAttrs"
          variant="outlined"
          class="px-2 v-col-6"
          :label="t('chargeMovement.description')"
          :error-messages="errors.description"
        />
      </v-row>
      <v-row>
        <price-input
          v-model="amount"
          v-bind="amountAttrs"
          class="px-2 v-col-6"
          :label="t('chargeMovement.amount')"
          :error-messages="errors.amount"
        />
        <v-text-field
          v-model="date"
          v-bind="dateAttrs"
          type="date"
          class="px-2 v-col-6"
          variant="outlined"
          :label="t('chargeMovement.date')"
          :error-messages="errors.date"
        />
      </v-row>
      <v-row>
        <v-spacer />
        <v-btn
          color="primary"
          type="submit"
          class="mb-4 mr-4"
          :disabled="submitButtonDisabled"
        >
          {{ mode === 'new' ? t('chargeMovementForm.createSubmit') : t('chargeMovementForm.updateSubmit') }}
        </v-btn>
      </v-row>
    </v-form>
    <v-alert
      v-if="isCreateError || isUpdateError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ t('userSession.defaultError') }}
    </v-alert>
  </v-card>
</template>
